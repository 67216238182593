/* eslint-disable */
import React, { useState } from 'react';
import { StyledNavbar } from './NavBar.styles';
import { Burger } from '../Burger/Burger';
import { Link } from 'gatsby';
import logo from '../images/logo.png';
// import chevron from '../images/chevron.png';
import AppContext, { UserContext } from '../AppContext';

const NavBar = props => {
  const { user } = props;

  const [toggle, setToggle] = useState(false);

  return (
    <AppContext>
      <UserContext.Consumer>
        {({ requestLogout }) => {
          return (
            <React.Fragment>
              <header>
                <StyledNavbar className="navbar">
                  <div className="nav-menu">
                    <div className="nav-menux">
                      <Link to="/">
                        <div className="nav-logo">
                          {!user ? (
                            <img src={logo} alt="devwares" />
                          ) : (
                            <img src={logo} alt="devwares" />
                          )}
                        </div>
                      </Link>
                      <div className="nav-list">
                        <ul>
                          {/* <Link to="/windframe" className="link">
                            <li>Windframe</li>
                          </Link> */}
                          <Link to="/windframe/" className="link">
                            <li>Windframe</li>
                          </Link>
                          <Link to="/windframe/form-builder" className="link">
                            <li>Form Builder</li>
                          </Link>
                          <Link to="/windframe/blocks" className="link">
                            <li>Blocks</li>
                          </Link>

                          <Link to="/blog" className="link">
                            <li>Blog</li>
                          </Link>
                          <Link to="/contact" className="link">
                            <li>Support</li>
                          </Link>
                          <Link to="https://windframe.tolt.io/" target="_blank" className="link">
                            <li>Refer and earn</li>
                          </Link>
                        </ul>
                      </div>
                    </div>

                    <div className="loop">
                      <a
                        href="https://www.producthunt.com/posts/windframe?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-windframe"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=303580&theme=light"
                          alt="Windframe - No code TailwindCSS page builder | Product Hunt"
                          style={{ width: '180px', height: '54px' }}
                        />
                      </a>
                    </div>

                    <div className="nav-buttons">
                      {user.loggedIn ? (
                        <ul className="nav navbar-nav">
                          <li className="dropdown">
                            {/* <a onClick={() => setToggle(!toggle)}>
                              <span
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                style={{ cursor: 'pointer' }}
                              >
                                Welcome
                              </span>
                            </a> */}
                            <Link
                              href="https://windframe.devwares.com/editor?id=formTemplate"
                              className="link"
                            >
                              <button className="signup">Get started</button>
                            </Link>
                            {toggle && (
                              <ul className="dropdown-menu" role="menu">
                                <li>
                                  <Link to="/windframe/subscription" className="link">
                                    Windframe
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/app/downloads" className="link">
                                    Downloads
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/app/account-settings" className="link">
                                    Account Settings
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/app/change-password" className="link">
                                    Change Password
                                  </Link>
                                </li>

                                <li>
                                  <a
                                    onClick={() => {
                                      requestLogout();
                                    }}
                                  >
                                    <span style={{ cursor: 'pointer' }} className="link">
                                      Logout
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            )}
                          </li>
                        </ul>
                      ) : (
                        <React.Fragment>
                          {/* <Link href="https://windframe.devwares.com/sign-up" className="link">
                            <button className="signin">
                              Sign up
                              <img src={chevron} alt=">" />
                            </button>
                          </Link> */}
                          <Link to="https://windframe.devwares.com/" className="link">
                            <button className="signup">Get Started</button>
                          </Link>
                        </React.Fragment>
                      )}
                    </div>
                    <Burger user={user} />
                  </div>
                </StyledNavbar>
              </header>
            </React.Fragment>
          );
        }}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default NavBar;
