import styled from "styled-components";

export const StyledFooter = styled.footer`
  box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, 0.04);
  padding: 30px 10px;
  @media screen and (max-width: 600px) {
    padding: 20px 1px;
  }
  background:#212121;
  color:white;
`;
export const FooterRow = styled.div`
         display: flex;
         flex-direction: row;
         .newsletter {
           input {
             width: 30%;
             height: 2.7em;
             border: 1.2px solid #b9b8b8;
             border-radius: 5px;
             padding: 2px 20px;
             margin: 2em 0px;
           }
         }
         @media (max-width: 600px) {
           flex-direction: column;
           .newsletter {
             input {
               width: 60%;
             }
           }
         }
         .footer-main {
           padding: 20px;
           img {
             height: 35px;
           }
           .footer-title {
             display: inline-flex;
             font-weight: 800;
             font-size: 20px;
             padding: 20px 10px;
             line-height: 160%;
             margin-left: 20px;
           }
           .footer-description {
             margin-top: 30px;
             font-style: normal;
             font-weight: 500;
             font-size: 16px;
             line-height: 130%;
             text-transform: capitalize;
           }
           .footer-rights {
             margin-top: 40px;
             font-style: normal;
             font-weight: normal;
             font-size: 12px;
             line-height: 180%;
             display: flex;
             align-items: center;
             letter-spacing: 0.01em;
           }
         }
         .footer-menu {
           padding: 20px;
           div {
             .footer-list {
               display: flex;
               flex-direction: column;
               justify-content: flex-start;
               padding: 10px;
               ul {
                 display: flex;
                 list-style: none !important;
                 flex-direction: column;
                 justify-content: flex-start;
                 li {
                   font-size: 16px;
                   padding: 10px 0;
                   cursor:pointer;
                   a {
                    color:inherit;
                   }
                 }
               }
               h2 {
                 font-weight: 800;
                 font-size: 20px;
                 padding: 10px 0px;
               }
             }
           }
         }
         .footer-socials {
           padding: 30px;
           h2 {
             font-weight: 800;
             font-size: 20px;
             padding: 10px 0px;
           }
           @media (max-width: 600px) {
             h2 {
               margin-left: 0;
               text-align: center;
             }
             ul {
               justify-content: center !important;
               padding-left: 0 !important;
             }
           }
           ul {
             display: flex;
             list-style: none !important;
             flex-direction: row;
             justify-content: flex-start;

             li {
               position: relative;
               color: white;
               background: #b4b4b4;
               width: 40px;
               height: 40px;
               border-radius: 50%;
               cursor:pointer;
               :not(:first-child) {
               margin-left:10px;

               }
               a {
                color:inherit;
               }
               .fab {
                 position: absolute;
                 cursor: pointer;
                 top: 50%;
                 left: 50%;
                 transform: translate(-50%, -50%);
               }
             }
           }
         }
       `;

export const Button = styled.button`
    margin: 20px 10px;
    padding: 9px 20px;
    border: none;
    box-shadow: 0px 5px 5px rgba(75, 93, 104, 0.1);
    border-radius: 5px;
    font-weight: 800;
    cursor: pointer !important;
`;