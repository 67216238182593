import React from 'react';
import { Link } from 'gatsby';
import '../../styles/scss/style.scss';

export const Feature = () => {
  return (
    <div className="feature feature-2">
      <div className="feature__wrap">
        <div className="feature__left">
          <img
            src={require('../../components/images/designers.png')}
            alt="loop design ui kit"
            className="feature__img"
          />
        </div>
        <div className="feature__right">
          <h2 className="feature__lead feature__lead--1 showcase__lead">
            Desgin System created to help speed up your next design project
          </h2>
          <p className="feature__text">
            Use the Loop design system UI kit to get your next design project to your clients as fast as possible.
            We have created alll the essential components you will need to create your designs and make them as elegant as possible
          </p>
          
          <br />
          
          <Link to="/app/downloads" className="btn1">
            Get Loop
          </Link>
        </div>
      </div>
    </div>
  );
};