import React from "react";
import { ShowcaseStyle } from "./Showcase.style";

const Showcase = (props) => {

  let { href, src, name, className } = props;

  return (
    <ShowcaseStyle className={className}>
      <a target="_blank" href={href} rel="noreferrer">
        <img  src={src} width="100%" height="auto" alt="showcaseImage"/>
        <div className="px-4 text-white text-center py-1">
        	<em>{name}</em>
        </div>
      </a>
    </ShowcaseStyle>
  );
};

export default Showcase;
