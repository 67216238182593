import React from 'react';

import '../../styles/scss/style.scss';
import styled from "./../../components/images/styledc.png";


export const Hero = () => {
  return (
          <div className="hero">
            <div className="feature__wrap">
              <div className="feature__left">
                <h2 className="feature__lead feature__lead--1 hero__lead">
                  Loop Design System UI Kit 
                </h2>
                <p className="feature__text">
                  Free design system UI kit to design your next website or webapp for busy designers and startups.<br /> <br /><strong>  Available in Figma </strong>
                </p>

                <div className="feature__buttons">
                    <a
                      className="btn1 btn__inverse"
                      href="/app/downloads"
                    >
                      <span className="btn__text">Download</span>
                    </a>

                  <a className="btn1 btn-white" rel="noreferrer" href="#preview">
                    
                    <span className="btn__text">Preview</span>
                  </a>
                </div>
              </div>
              <div className="feature__right">
                <img
                  src={require('../../components/images/loopdesign.png')}
                  alt="#"
                  className="feature__img"
                />
              </div>
            </div>

          </div>
  );
};