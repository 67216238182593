import React, { useRef, useEffect } from "react";
import NavBar from "../DarkNavBar/NavBar";
import { Footer } from "../DarkFooter/Footer";
import { PageWrapper } from "./Layout.styles";
import { Global } from '@emotion/core';
import { baseStyles } from '../styles/GlobalStyles';
import AppContext, { UserContext } from "../AppContext";
import { darkTheme } from './../theme/index';
import '../../utils/App.css';

export const Layout = (props) => {

  const layoutDark = useRef(null);

  useEffect(() => {
    layoutDark.current.parentNode.style.background = "#212121";

    return () => {
      layoutDark.current.parentNode.style.background = "#fff";
    }
  }, [])

  return (
    <AppContext>
      <Global styles={baseStyles} />
      <UserContext.Consumer>
        {({ user }) => (
          <div ref={layoutDark} style={{ background: '#212121' }}>
            <div className="container" ref={layoutDark} style={{ background: '#212121' }}>
              <PageWrapper>
                <NavBar user={user} theme={darkTheme} />
                {props.children}
                <Footer />
              </PageWrapper>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};
