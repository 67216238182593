import React from 'react';
import Showcase from '../Showcase/Showcase.js';

import '../../styles/scss/style.scss';

export const ShowcaseSection = () => {

  const options = [
    {
      href:"",
      src:require('../../components/images/aloopcolor.png'),
      name:"Color"
    },
    {
      href:"",
      src:require('../../components/images/aloopcards.png'),
      name:"Cards"
    },
    {
      href:"",
      src:require('../../components/images/aloopforms.png'),
      name:"Forms"
    },
    {
      href:"",
      src:require('../../components/images/aloopaccordion.png'),
      name:"Accordion"
    },
    {
      href:"",
      src:require('../../components/images/aloopalert.png'),
      name:"Alert"
    },
    {
      href:"",
      src:require('../../components/images/aloopbuttontool.png'),
      name:"Button Toolbar"
    },
    {
      href:"",
      src:require('../../components/images/aloopbuttons.png'),
      name:"Buttons"
    },
    {
      href:"",
      src:require('../../components/images/aloopmodals.png'),
      name:"Modals"
    },
    {
      href:"",
      src:require('../../components/images/alooppagination.png'),
      name:"Pagination"
    },
    {
      href:"",
      src:require('../../components/images/aloopdatepicker.png'),
      name:"Datepicker"
    },
    {
      href:"",
      src:require('../../components/images/aloopdrop.png'),
      name:"Dropdown"
    },
    {
      href:"",
      src:require('../../components/images/aloopbread.png'),
      name:"Breadcrumb"
    }
  ];

  return (
    <div className="showcase" id="preview">
      <div className="showcase__lead">Components Preview</div>

      <div className="showcase__items">
        <div className="showcase__wrap showcase__list">
          {/* <!-- Item 1 start --> */}
          {options.map((item, index) => (
            <Showcase key={index} name={item.name} href={item.href} src={item.src}/>
          ))}
          {/*<div className="showcase__item">
                      <img
                      src={require('../../components/images/aloopcolor.png')}
                      className="showcase__img--img"
                      alt="#"
                    />
                    </div>
                    <div className="showcase__item">
                      <img
                        src={require('../../components/images/aloopcards.png')}
                        className="showcase__img--img"
                        alt="#"
                      />
                    </div>
                    <div className="showcase__item">
                      <img
                        src={require('../../components/images/aloopforms.png')}
                        className="showcase__img--img"
                        alt="#"
                      />
                    </div>
                    <div className="showcase__item">
                      <img
                        src={require('../../components/images/aloopaccordion.png')}
                        className="showcase__img--img"
                        alt="#"
                      />
                    </div>
                    <div className="showcase__item">
                      <img
                        src={require('../../components/images/aloopalert.png')}
                        className="showcase__img--img"
                        alt="#"
                      />
                    </div>
                    <div className="showcase__item">
                      <img
                        src={require('../../components/images/aloopbuttontool.png')}
                        className="showcase__img--img"
                        alt="#"
                      />
                    </div>
                  </div>
                    <div className="showcase__wrap showcase__list">
                    <div className="showcase__item">
                      <img
                        src={require('../../components/images/aloopbuttons.png')}
                        className="showcase__img--img"
                        alt="#"
                      />
                    </div>
                    <div className="showcase__item">
                      <img
                        src={require('../../components/images/aloopmodals.png')}
                        className="showcase__img--img"
                        alt="#"
                      />
                    </div>
                    <div className="showcase__item">
                      <img
                        src={require('../../components/images/alooppagination.png')}
                        className="showcase__img--img"
                        alt="#"
                      />
                    </div>
                    <div className="showcase__item">
                      <img
                        src={require('../../components/images/aloopdatepicker.png')}
                        className="showcase__img--img"
                        alt="#"
                      />
                    </div>
                    <div className="showcase__item">
                      <img
                        src={require('../../components/images/aloopdrop.png')}
                        className="showcase__img--img"
                        alt="#"
                      />
                    </div>
                    <div className="showcase__item">
                      <img
                        src={require('../../components/images/aloopbread.png')}
                        className="showcase__img--img"
                        alt="#"
                      />
                    </div>*/}
        </div>
      </div>

    </div>
  );
};