import styled from "styled-components";

export const ShowcaseStyle = styled.div`

    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform .25s ease-out;
    border-radius:5px;
    overflow:hidden;

	&:hover, &:focus {
    	transform: scale(1.05);
	}
	&:active {
	    transform: scale(.9);
	}

	a {
		text-decoration:none;
	}

	div {
		background:rgba(0, 0, 0, 0.7);
		position:absolute;
		right:0px;
		left:0px;
		bottom:0px;
	}
`;